$(document).ready(() => {
    $("img.check-load").one("load", function() {
        // do stuff
        $(this).addClass('loaded');
            // .removeClass('check-load');
    }).each(function() {
        if(this.complete) {
            // $(this).load(); // For jQuery < 3.0
            $(this).trigger('load'); // For jQuery >= 3.0
        }
    });
    $('#header-content > *').each(function(i) {
        let classes = 'animated fast ';
        switch (i) {
            case 0:
                classes += 'fadeInDown delay-0-5s';
                break;
            case 1:
                classes += 'fadeIn delay-1s';
                break;
            case 2:
                classes += 'fadeInUpSmall delay-1-5s';
                break;
            default:
                classes += 'fadeInLeft delay-2s';
        }
        $(this).addClass(classes)
    });
    $('#header-content').css('display', 'block');
    var AOS = require('aos');
    AOS.init();
    // $('.banner-slick').slick({
    //     infinite: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: true,
    //     autoPlay: true,
    //     autoplaySpeed: 5000
    // });
    var registerModal = $('#registerModal');
    var registerForm = $('#register');
    var contactForm = $('#contact-form');
    var contactEmail = $('#contact-email');
    registerForm.validate({
        highlight: function (input) {
            $(input).addClass('is-invalid').removeClass('is-valid');
        },
        unhighlight: function (input) {
            $(input).removeClass('is-invalid').addClass('is-valid');
        },
        errorPlacement: function (error, element) {
            $(element).parents('.form-group').append(error);
        },
        submitHandler: function() {
            $('#submit-message').text('').addClass('d-none').removeClass('d-block');
            var submit_button = $('#register button[type=submit]');
            submit_button.prop('disabled', true);
            submit_button.find('span').addClass('d-none');
            submit_button.find('.loading-spinner').removeClass('d-none');
            $.ajax({
                url: '/send-mail',
                type: 'post',
                data: registerForm.serialize(),
                dataType: 'json',
                success: function(data) {
                    /*
                    {
                    code: number,
                    message: string
                    }
                     */
                    if (data.code === 200) {
                        // $("#modal_edit").modal('hide');
                        registerForm[0].reset();
                        $('#submit-message').text('Your registration information had been sent to us. Thank you!').removeClass('d-none').addClass('d-block').addClass('bg-success').removeClass('bg-danger');
                        contactEmail.val();
                    } else {
                        // $("#modal_edit").modal('hide');
                        // alert('Error updating data');
                    }
                }, // /succes
                error: function() {
                    $('#submit-message').text('Error occurs in submit data. Please try again!').removeClass('d-none').addClass('d-block').addClass('bg-danger').removeClass('bg-success');
                },
                complete: function() {
                    // setTimeout(function () {
                        submit_button.prop('disabled', false);
                        submit_button.find('span').removeClass('d-none');
                        submit_button.find('.loading-spinner').addClass('d-none');
                    // }, 3000);
                }
            }); // /ajax
            return false;
        }
    });
    contactForm.validate({
        highlight: function (input) {
            $(input).addClass('is-invalid').removeClass('is-valid');
        },
        unhighlight: function (input) {
            $(input).removeClass('is-invalid').addClass('is-valid');
        },
        errorPlacement: function (error, element) {
            $(element).parents('.form-group').append(error);
        },
        submitHandler: function() {
            // TODO: set email for register form
            registerForm.find('#inp_email').val(contactEmail.val());
            console.log(registerForm.find('#inp_email').val());
            // TODO: open register modal
            registerModal.modal('show');
            return false;
        }
    });

    registerModal.on("hidden.bs.modal", function () {
        $('#submit-message').text('').addClass('d-none').removeClass('d-block');
    });
});
