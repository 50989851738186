require('offline-plugin/runtime').install();
import $ from 'jquery';
import 'jquery-validation';
// console.log(jQuery);
// window.$ = jQuery;
// window.jQuery = jQuery;
import 'bootstrap/js/src/popover.js'
import 'bootstrap/js/src/scrollspy.js'
import 'bootstrap/js/src/toast.js'
import 'bootstrap/js/src/tooltip.js'
import 'bootstrap/js/src/button.js'
import 'bootstrap/js/src/modal.js'
import 'bootstrap/js/src/util.js'
// import 'bootstrap/dist/js/bootstrap.bundle';
import 'slick-carousel';
import './404.html';
import './index.html';
import './styles/index.scss';
import './scripts/script.js';

